@font-face {
  font-family: "visia-pro";
  src: url("../src/fonts/VisiaPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "visia-pro-outline";
  src: url("../src/fonts/VisiaPro-RegularOutline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "visia-pro-bold";
  src: url("../src/fonts/VisiaPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "visia-pro", sans-serif !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10rem;
}

.navbar-logo {
  padding: 1rem;
}

.navbar-logo img {
  height: 40px;
}

.website-announce {
  display: flex;
  height: 85vh;
  justify-content: center;
  align-items: center;
}

.website-announce span {
  font-size: 4rem;
}

.gradient-text {
  background-clip: text;
  -webkit-background-clip: text; /* For Safari/WebKit */
  color: transparent;
  background-image: linear-gradient(45deg, #a86fe3, #ed726a, #f2ab5d);
}

.main-content {
  height: 94vh;
  position: fixed;
}

.main-content-left {
  display: flex;
  align-items: end;
  justify-content: end;
}

.main-content-right {
  padding: 10rem 12rem 10rem 5rem;
}

.main-content-right h1 {
  font-size: 5rem;
  margin: 7px 0;
  font-family: "visia-pro-bold", sans-serif !important;
}

.main-content-right p {
  font-size: 26px;
  color: #7a7a7a;
  line-height: 36px;
}

.red-dot {
  color: #f21818;
}

.cta-button-1 {
  padding: 1rem 2rem !important;
  border-radius: 0 !important;
  background-color: black !important;
  font-size: 18px !important;
  font-family: "visia-pro-bold", sans-serif !important;
}

.cta-button-1::before {
  content: "";
  position: absolute;
  inset: 0;
  transform: translate(8px, 8px);
  z-index: -1;
  background: linear-gradient(45deg, #a86fe3, #ed726a, #f2ab5d) !important;
  transition: 0.1s ease-out;
}

.cta-button-1:hover::before {
  transform: translate(0, 0);
  transition: 0.1s ease-in;
}

.preloader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.preloader h2 {
  font-size: 50px;
  margin-bottom: 16px;
}

@media (max-width: 499px) {
  .navbar {
    padding: 0 1rem;
  }

  .website-announce span {
    font-size: 1.5rem;
  }

  .main-content {
    flex-direction: column-reverse !important;
    justify-content: space-between;
  }

  .main-content-left img {
    height: 200px !important;
    margin: auto;
  }

  .main-content-right {
    padding: 5rem 3rem 3rem 3rem;
  }

  .main-content-right h1 {
    font-size: 2rem;
  }
}
